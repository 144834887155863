import "intl-tel-input/styles"

import { Controller } from "@hotwired/stimulus";
import type { Iti } from "intl-tel-input";
import IntlTelInput from "intl-tel-input/intlTelInputWithUtils"

export default class extends Controller<HTMLFormElement> {
  static targets = ["contactType", "countryCodeInput", "emailControl", "phoneControl", "phoneInput", "linkControl", "recordType"];
  static values = { contactType: String, phone: String };

  declare readonly contactTypeTarget: HTMLInputElement;
  declare readonly countryCodeInputTarget: HTMLInputElement
  declare readonly emailControlTarget: HTMLDivElement;
  declare readonly linkControlTarget: HTMLDivElement;
  declare readonly phoneControlTarget: HTMLDivElement;
  declare readonly phoneInputTarget: HTMLInputElement;
  declare readonly recordTypeTarget: HTMLInputElement;
  declare contactTypeValue: string;

  private iti: Iti | null = null;

  contactTypeTargetConnected(element: HTMLInputElement) {
    if (element.checked) {
      this.contactTypeValue = element.value;
    }
  }

  contactTypeValueChanged(newValue: string) {
    if (newValue === 'sms') {
      this.phoneControlTarget.style.display = 'block';
      this.emailControlTarget.style.display = 'none';
      this.linkControlTarget.style.display = 'none';
    }

    if (newValue === 'email') {
      this.emailControlTarget.style.display = 'block';
      this.phoneControlTarget.style.display = 'none';
      this.linkControlTarget.style.display = 'none';
    }

    if (newValue === 'link') {
      this.linkControlTarget.style.display = 'block';
      this.phoneControlTarget.style.display = 'none';
      this.emailControlTarget.style.display = 'none';
    }
  }

  phoneInputTargetConnected(element: HTMLInputElement) {
    console.log('phoneInputTargetConnected');
    this.iti = IntlTelInput(element, {
      initialCountry: "us",
    });
  }

  onSubmit(event: SubmitEvent) {
    event.preventDefault();
    const target = event.target as HTMLElement;
    const button = target.closest('button');
    this.phoneInputTarget.value = this.iti?.getNumber() || '';
    this.recordTypeTarget.value = button?.value || '';
    this.countryCodeInputTarget.value = this.iti?.getSelectedCountryData().iso2 || '';
    this.element.submit();
  }

  updateContactType(event: Event) {
    const target = event.target as HTMLInputElement;
    this.contactTypeValue = target.value;
  }
}
